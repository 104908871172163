@import "../../../theme-settings.scss";

.packet-block {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 575px) {
  .packet-block-tablet,
  .packet-block-desktop {
    display: none;
  }
}
@media screen and (min-width: 576px) and (max-width: 991px) {
  .packet-block-mobile,
  .packet-block-desktop {
    display: none;
  }
  .packet-block-tablet,
  .packet-block-mobile-override {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .packet-block-mobile,
  .packet-block-tablet {
    display: none;
  }
  .packet-block-desktop,
  .packet-block-mobile-override,
  .packet-block-tablet-override {
    display: block;
  }
}

.packet-card {
  background: white;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .invisible-break {
    display: block;
  }
  .packet-price {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
  }
  .packet-title {
    margin: 10px 0;
    font-size: 30px;
    font-weight: 700;
    font-family: $headersFont;
    padding-right: 30px;
    a {
      color: black;
      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
  }
  .packet-desc {
    font-size: 15px;
    font-weight: 500;
  }
  .packet-payment-status {
    button {
      margin-top: 15px;
      width: 170px;
    }
  }
  @media screen and (min-width: 576px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .packet-payment-status {
      button {
        margin: 0;
      }
    }
    .packet-title,
    .packet-desc {
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: 768px) {
    .packet-price,
    .packet-desc {
      font-size: 18px;
    }
    .packet-title {
      font-size: 35px;
      .packet-subtitle {
        display: block;
        font-size: 25px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    justify-content: flex-start;
    .packet-desc,
    .packet-price {
      flex: 0 0 55%;
      margin-bottom: 0;
    }
    .packet-title,
    .packet-payment-status {
      flex: 0 0 45%;
      margin: 0;
      order: unset;
    }
    .packet-payment-status {
      text-align: right;
    }
    .packet-price,
    .packet-payment-status {
      margin-top: 20px;
    }
  }
  @media screen and (min-width: 1200px) {
    padding: 20px 35px;
    .packet-desc {
      flex: 0 0 calc(100% - 280px - 200px - 200px);
      font-size: 18px;
    }
    .packet-price {
      flex: 0 0 200px;
      text-align: center;
      margin: 0;
      font-size: 18px;
    }
    .packet-payment-status {
      text-align: right;
      flex: 0 0 200px;
      margin: 0;
      button {
        font-size: 20px;
        width: 200px;
      }
    }
    .packet-title {
      margin-top: -5px;
      font-size: 30px;
      flex: 0 0 280px;
    }
  }
  @media screen and (min-width: 1400px) {
    .packet-desc {
      flex: 0 0 calc(100% - 335px - 200px - 200px);
    }
    .packet-title {
      font-size: 35px;
      flex: 0 0 335px;
    }
    .packet-desc,
    .packet-price {
      font-size: 20px;
    }
  }
  @media screen and (min-width: 1600px) {
    .packet-desc {
      flex: 0 0 calc(100% - 385px - 200px - 200px);
    }
    .packet-title {
      font-size: 40px;
      flex: 0 0 385px;
    }
  }
}
