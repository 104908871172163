@import "./theme-settings.scss";

// login page
.noauth-layout .fullsize-bg {
  background-color: black;
  background-position: top 50px right 100px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.noauth-layout {
  align-items: flex-start;
  background: none;
  @media screen and (min-width: 1024px) {
    padding: 40px 50px;
  }
  > main {
    left: 10%;
  }
  .fullsize-bg {
    z-index: -1;
  }
}
@media screen and (min-width: 768px) {
  .noauth-layout > main .wrapper-content {
    padding: 30px 60px 40px 60px;
  }
}

// navbar
.navbar-menu > ul li.search-input input {
  border: none;
  background: url(./static/search_border.svg);
  background-position: bottom center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.main-navbar .navbar-menu > ul li a {
  font-weight: bold;
  &:hover,
  &:focus {
    filter: none;
    color: white;
    text-decoration: line-through;
  }
}
.main-navbar .logo {
  a {
    flex-direction: row-reverse;
    span {
      margin-right: 40px;
      margin-left: 0;
    }
  }
}
.main-navbar .logo span {
  display: none;
  @media screen and (min-width: 1300px) {
    display: none;
    font-size: 85px;
    display: inline-block;
    margin-left: 60px;
    height: 70px;
    background: url(./static/vodText.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
    width: 180px;
  }
}
.navbar-menu > ul li.search-input {
  button:hover,
  button:focus {
    img {
      filter: none;
    }
  }
}
.navbar-menu > ul li {
  &.admin-panel-url a img {
    height: 35px;
    width: 35px;
  }
  &:last-child a img {
    width: 40px;
    height: 30px;
  }
}

// global
a,
.ant-btn:not([disabled]) {
  &:hover,
  &:focus {
    text-decoration: line-through;
    * {
      text-decoration: line-through;
    }
  }
}

// vod
.filters-switcher .filters button {
  &:hover,
  &.mixitup-filter-active,
  &:focus {
    text-decoration: line-through;
    color: white;
    font-weight: bold;
  }
  &.all {
    &.mixitup-filter-active,
    &:hover,
    &:focus {
      color: white;
      border-color: white;
    }
  }
}

// slider
.slide {
  .slide-desc {
    .slide-title {
      text-decoration: underline;
    }
    .slide-text:hover {
      text-decoration: none;
    }
  }
}
.slide .slide-bg {
  background-position: left center !important;
}
@media screen and (min-width: 1400px) {
  .slider-2 .slide .slide-content .slide-desc {
    .slide-title {
      font-size: 35px;
    }
    .slide-text {
      font-size: 18px;
    }
  }
}
@media screen and (min-width: 2200px) {
  .slide {
    min-height: 500px;

    .slide-content {
      min-height: 500px;
      position: relative;
    }
  }
  .slider-2 .slide .slide-content .slide-desc {
    .slide-title {
      font-size: 45px;
    }
    .slide-text {
      font-size: 20px;
    }
  }

  // @media screen and (min-width: 768px) {
  //   .slide {
  //     display: flex;
  //     flex-direction: row-reverse;
  //     .slide-bg,
  //     .slide-content {
  //       position: static;
  //       flex: 0 0 350px;
  //     }
  //     .slide-content {
  //       display: flex;
  //       align-items: flex-end;
  //       background: black;
  //     }
  //     .slide-desc {
  //       position: static;
  //       background: black;
  //       color: white;
  //     }
  //     .slide-bg {
  //       height: 400px;
  //       flex: 0 0 calc(100% - 350px);
  //     }
  //   }
  // }
  // @media screen and (min-width: 1024px) {
  //   .slide {
  //     .slide-content {
  //       flex: 0 0 400px;
  //       .slide-desc {
  //         padding: 45px;
  //       }
  //     }
  //     .slide-bg {
  //       flex: 0 0 calc(100% - 400px);
  //     }
  //   }
  // }
  .slide-text:hover {
    text-decoration: none;
  }
}
@media screen and (min-width: 1400px) {
  .slider-2 .slide .slide-content .slide-desc {
    .slide-title {
      font-size: 35px;
    }
    .slide-text {
      font-size: 18px;
    }
  }
}
@media screen and (min-width: 2200px) {
  .slide {
    min-height: 500px;

    .slide-content {
      min-height: 500px;
      position: relative;
    }
  }
  .slider-2 .slide .slide-content .slide-desc {
    .slide-title {
      font-size: 45px;
    }
    .slide-text {
      font-size: 20px;
    }
  }
}
// @media screen and (min-width: 768px) {
//   .slide {
//     display: flex;
//     flex-direction: row-reverse;
//     .slide-bg,
//     .slide-content {
//       position: static;
//       flex: 0 0 350px;
//     }
//     .slide-content {
//       display: flex;
//       align-items: flex-end;
//       background: black;
//     }
//     .slide-desc {
//       position: static;
//       background: black;
//       color: white;
//     }
//     .slide-bg {
//       height: 400px;
//       flex: 0 0 calc(100% - 350px);
//     }
//   }
// }
// @media screen and (min-width: 1024px) {
//   .slide {
//     .slide-content {
//       flex: 0 0 400px;
//       .slide-desc {
//         padding: 45px;
//       }
//     }
//     .slide-bg {
//       flex: 0 0 calc(100% - 400px);
//     }
//   }
// }

// content
.auth-layout .content-wrapper .content > h1,
h2 {
  text-decoration: underline;
}
.content-wrapper-full h1,
h2 {
  text-decoration: none !important;
}

// admin
.admin-menu li {
  &:hover,
  &.active,
  &:focus {
    text-decoration: line-through;
    img {
      filter: none;
    }
  }
}

// cookies
#rcc-confirm-button {
  background: white !important;
  color: black !important;
}
.CookieConsent {
  a {
    color: white !important;
    text-decoration: underline;
  }
}

.noauth-layout .ant-card-body {
  div:first-child {
    a,
    a:hover,
    a:focus {
      text-decoration: none !important;
    }
  }
}

.auth-layout:not(.full-width) .content-wrapper {
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 900px) {
    margin-top: 0;
    min-height: calc(100vh - 122px);
  }
  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 122px);
  }
  @media screen and (min-width: 1200px) {
    min-height: calc(100vh - 171px);
  }
}
.auth-layout.full-width {
  .content-wrapper-full {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 80px);
    @media screen and (min-width: 768px) {
      min-height: calc(100vh - 80px);
    }
    @media screen and (min-width: 900px) {
      min-height: calc(100vh - 144px);
    }
    @media screen and (min-width: 992px) {
      min-height: calc(100vh - 144px);
    }
    @media screen and (min-width: 1200px) {
      min-height: calc(100vh - 193px);
    }
    .content-wrapper {
      min-height: 0 !important;
    }
  }
}

footer .footer-wrapper .footer-left {
  @media screen and (min-width: 1200px) {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1400px) {
    flex: 0 0 calc(100% - 350px);
  }
}
footer .footer-wrapper .footer-right .mini-footer {
  @media screen and (min-width: 1200px) {
    justify-content: flex-start;
  }
  @media screen and (min-width: 1400px) {
    justify-content: flex-start;
  }
}

.path-logowanie.noauth-layout > main .wrapper-content > .ant-row:first-child {
  margin-bottom: 30px;
}
