@import "../../../theme-settings.scss";

.signup-form {
  label + .ant-row.ant-form-item {
    margin-bottom: 15px;
  }
  .ant-picker.datePicker,
  label + .ant-row.ant-form-item .ant-form-item-control-input {
    display: none;
  }
}

.free-code {
  padding: 15px;
  background: $primary-color;
  color: white;
  font-weight: bold;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
