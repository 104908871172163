@import "../../../theme-settings.scss";

.edit-user-form {
  label + .ant-row.ant-form-item {
    // margin-bottom: 0;
  }
  .ant-picker.datePicker {
    display: none;
  }
}
