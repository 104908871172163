@import url("https://use.typekit.net/pdw8kyw.css");

$fontdir: "./static/fonts/";

@font-face {
  font-family: "PFBagueUniPro";
  src: url($fontdir + "PFBagueUniPro-Bold.eot");
  src: url($fontdir + "PFBagueUniPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url($fontdir + "PFBagueUniPro-Bold.woff") format("woff"),
    url($fontdir + "PFBagueUniPro-Bold.svg#PFBagueUniPro-Bold")
      format("svg");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "PFBagueUniPro";
  src: url($fontdir + "PFBagueUniPro-Reg.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}

// @font-face {
//   font-family: "oswald";
//   src: url($fontdir + "oswald-bold-webfont.eot");
//   src: url($fontdir + "oswald-bold-webfont?#iefix")
//       format("embedded-opentype"),
//     url($fontdir + "oswald-bold-webfont.woff") format("woff");
//   font-style: normal;
//   font-weight: bold;
// }

// @font-face {
//   font-family: "oswald";
//   src: url($fontdir + "oswald-webfont.eot");
//   src: url($fontdir + "oswald-webfont?#iefix")
//       format("embedded-opentype"),
//     url($fontdir + "oswald-webfont.woff") format("woff");
//   font-style: normal;
//   font-weight: normal;
// }

$headersFont: "PFBagueUniPro";
$textFont: "PFBagueUniPro";

$primary-color: black;
$header-bg-color: black;
$blue-color: #0d77ce;
$light-blue-color: #0d77ce;
$green-color: #09b437;
$light-green-color: #09b437;
$orange-color: #f5af17;
$light-orange-color: #f5af17;
$red-color: #cf3d3d;
$light-red-color: #cf3d3d;
$grey-color: #666;
$placeholder-color: #747474;
$wrapper-bg-color: #eceff1;

$navbarLogoWidthSm: 240px;
$navbarLogoWidthMd: 280px;
$navbarLogoWidthLg: 320px;

$miniNavbarLogoWidthSm: 315px;
$miniNavbarLogoWidthMd: 340px;
$miniNavbarLogoWidthLg: 340px;

$navbarDrawerBreakpoint: 900px;

// wrapper min height
// frontpage
$wrapperFWMinheightXs: 80px;
$wrapperFWMinheightSm: 80px;
$wrapperFWMinheightMd: 125px;
$wrapperFWMinheightLg: 125px;
$wrapperFWMinheightXl: 184px;
// static page
$wrapperMinheightXs: 80px;
$wrapperMinheightSm: 80px;
$wrapperMinheightMd: 103px;
$wrapperMinheightLg: 103px;
$wrapperMinheightXl: 162px;

$slideBg: black;
